.header-content {
    font-family: "Roboto", sans-serif;
    font-size: 5px;
    font-style: normal;
}

.react-multiple-carousel__arrow--left{
    display: none;
}

.react-multiple-carousel__arrow--right{
    display: none;
}

.react-multi-carousel-track {
    padding: 0 0 0px;
}

.react-multi-carousel-list {
    padding: 0rem 0 3rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: #0A80D4;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: #0A80D4 !important;
    border: 1px solid #0A80D4;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.SILDERHEIGHT{
    height: 550px;
}

.OurHostingServices {
    font-family: "Laila", serif;
    text-align: center;
    padding: 30px;
    margin-top: 20px;
}

.OurHostingServices h2 {
    font-size: 27px;
    color: #1b232f;
    font-weight: 600;
    margin-bottom: 20px;
}

.OurHostingServices p {
    font-size: 17px;
    color: #596777;
    font-weight: 400;
    margin-bottom: 20px;
}

.BorderMoreArticles2 {
    border-bottom: 2px solid #F8BD43;
    width: 13%;
    margin-bottom: 10px;
}

.BorderProductSub {
    border-bottom: 3px solid #22B678;
    width: 15%;
    margin-bottom: 10px;
    margin-top: 14px;
}

.BorderMoreArticles3 {
    border-bottom: 2px solid #F65B5F;
    width: 25%;
    margin-bottom: 10px;
}

.BorderMoreArticles4 {
    border-bottom: 2px solid #fff;
    width: 25%;
    margin-bottom: 10px;
}

.PRODUCT-QUALITY {
    padding: 38px;
}

.ProductPadding {
    padding: 40px;
}


.Coloorr {
    background: #CED6D9;
    /* background-color: #2BAAF4; */
}

.hr-lines:before {
    content: " ";
    display: block;
    height: 1.3px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 0;
    background: #048AB2;
}

.hr-lines {
    position: relative;
}

.hr-lines {
    position: relative;
    max-width: 500px;
    margin: 15px auto;
    text-align: center;
}

.hr-lines:after {
    content: " ";
    height: 1.3px;
    width: 200px;
    background: #048AB2;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}


.poppins-All {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.BorderMoreArticles {
    border-bottom: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 20%;
}


.LatoFontFamily {
    font-family: 'Lato', sans-serif;
}

.abc {
    background-color: aqua;
}

.mission:hover {
    padding: 10px;
    box-shadow: 10px 10px 8px 10px #888888;
    border: solid 2px black;
    border-radius: 20;
    font-size: large;
}

.navtarget:hover {
    box-shadow: 2px 1px 5px 0px #30992f;
    color: #0066cb;
}

.footerTarget:hover {
    padding: 2px;
    box-shadow: 2px 1px 5px 0px orange;
    font-size: large;
}

.brandCard:hover {
    font-size: large;
}

.brandCardfooter {
    background: none;
    border: none;
}

.brandCardfooter:hover {
    background-color: #30992f;
}

.product :hover {
    color: orange;
    font-size: large;
}

.mainContent:hover {
    font-size: large;
    color: black;
}

.boderRadius {
    border: 0;
    outline: 0;
    border-bottom: 2px solid #0066cb;
}

.boderRadius:hover {
    background-color: #3b9d3b;
    border-color: black;
    font-size: large;
}

.aaa {
    background: -webkit-linear-gradient(#56bc4a, #28666e);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.colo {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    white-space: nowrap;
    padding: 10px;
    background-color: #25291C;
}

.fontFamilyHeader {
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
}

.fontFamilyHeader1 {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily1 {
    font-family: 'Kanit', sans-serif;
    line-height: 40px;
}

.SolutionDropDownFontFamily2 {
    font-family: 'Kanit', sans-serif;
    line-height: 23px;
}

.SolutionDropDown {
    font-family: 'Roboto', sans-serif;
}


.h1FontFamily {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
}

.h1FontFamily1 {
    font-family: 'Ubuntu', sans-serif;
}

.h1FontFamilyLineHeight {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 50px;
}

.h1FontFamilyLineHeight1 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 60px;
}

.FOOTERLINK {
    color: white;
    font-weight: 400;
}

.FOOTERLINK:hover {
    color: #7DCBEC;
}

.READMOREBUTTON {
    transition: transform 1s;
    background-color: transparent;
    cursor: pointer;

}

.READMOREBUTTON:hover {
    transform: scale(1.1);
    background-color: #25291C;
}


.BUYBUTTON {
    background-color: #0D6EFD;
    cursor: pointer;
}

.BUYBUTTON:hover {
    background-color: #25291C;
}


.PRODUCTCONTACTBUTTON {
    background-color: #25291C;
    cursor: pointer;
    color: #fff;
}

.PRODUCTCONTACTBUTTON:hover {
    background-color: white;
    color: #25291C;
    border: 1px solid #25291C;
}

.contactBUTTON {
    transition: transform 1.6s;
    overflow: hidden;
    border: 1px solid gray;
    border-radius: 1rem;
    background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
}

.contactBUTTON:hover {
    color: black;
    transform: scale(1.1);
    background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
}

.Header-Size {
    overflow: hidden;
    font-size: 1rem;
    padding: 2.8%;
    font-weight: 400;
    text-align: center;
}

.CommomColor {
    color: #2b2b2b;
}

.nav-link {
    color: #32409a;
}

.CommomColor:hover {
    color: #32409a;
}

.CommomColorHome {
    color: #32409a;
}


.ImgHeight {
    height: 4rem;
    width: 100%;
}

.ImgHeightFooter {
    height: 5rem;
    width: 80%;
}

.CommonContent {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
}

.CommonIMG {
    width: 100%;
    height: 250px;
    border-radius: 10px;
}

.WELCOME-TO-PASHUMITRA {
    font-size: 35px;
}

.SubmitButton {
    height: 60px;
    border: 1px solid #7DCBEC;
    background-color: #7DCBEC;
    color: #fff;
    width: 140px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 18px;
    border-radius: 6px;
}

.SubmitButton:hover {
    height: 60px;
    border: 1px solid #32409A;
    background-color: #32409A;
    color: #fff;
    width: 140px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 18px;
    border-radius: 6px;
}

.ProductBox {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    padding: 30px;
    border-radius: 2px;
    border-left: 4px solid #fff;
}

.ProductBox:hover {
    background-color: #fff;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    padding: 27px;
    border-left: 4px solid #22B678;

}
/* 
.ProductImgeSize img {
    height: 14rem;
} */


.ReadMore button {
    background-color: #fff;
    border: 2px solid black;
    color: #151515;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}

.ReadMore button:hover {
    background-color: black;
    color: #fff;
    border: 2px solid black;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}

.CommonBack button {
    background-color: #070E26;
    border: 2px solid black;
    color: #fff;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 60px;
    margin-bottom: 25px;
}



.ProductText {
    color: #656565;
    font-size: 13px;
    text-align: center;
}

.ProductText:hover {
    color: red;
    font-size: 13px;
    text-align: center;
}

.ContentBox {
    /* font-size: 16px; */
    color: #242424;
    font-weight: 400;
}

.ContentBox p {
    line-height: 1.9 !important;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.ImgSize {
    height: 6rem;
}

.ImgSizeSilder {
    height: 12rem;
    border-radius: 10px;
}

.main_size h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 30px 0 0;
    text-align: center;
}


/* width */
::-webkit-scrollbar {
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}



@media screen and (max-width:1200px) {

    .ImgSize {
        height: 6rem;
    }

    .ImgHeight {
        height: 4rem;
    }

    .Header-Size {
        overflow: hidden;
        font-size: 14px;
        padding: 1%;
    }
}

@media screen and (max-width:1120px) {
    .ImgSize {
        height: 6rem;
    }

    .ImgHeight {
        height: 3.5rem;
    }

    .Header-Size {
        overflow: hidden;
        font-size: 14px;
        padding: 1.7%;
        text-align: start;
    }
}

/* @media screen and (max-width:990px) {
    .ImgSizeSilder {
        height: 12rem;
        width: 14rem;
        border-radius: 10px;
    }
} */

@media screen and (max-width:767px) {
    .CommonContent {
        font-size: 20px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .hr-lines:before {
        content: " ";
        display: block;
        height: 1.3px;
        width: 120px;
        position: absolute;
        top: 50%;
        left: 0;
        background: #048AB2;
    }

    .hr-lines {
        position: relative;
    }

    .hr-lines {
        position: relative;
        max-width: 300px;
        margin: 15px auto;
        text-align: center;
    }

    .hr-lines:after {
        content: " ";
        height: 1.3px;
        width: 120px;
        background: #048AB2;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
    }

    .PRODUCT-QUALITY {
        padding: 28px;
    }

    .WELCOME-TO-PASHUMITRA {
        font-size: 33px;
    }

    .ProductPadding {
        padding: 20px;
    }

    .ImgSize {
        height: 6rem;
        /* width: 100%; */
    }
}

@media screen and (max-width:480px) {
    .SILDERHEIGHT{
        height: 300px;
    }

    .CommonBack button {
        background-color: #070E26;
        border: 2px solid black;
        color: #fff;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 500;
        padding: 14px 70px;
        margin-bottom: 20px;
    }
}